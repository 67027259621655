import React from 'react';
import { SectionWrapper, SizedBox } from '../../components';
import { Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import image1 from '../../assets/OBJECTS-3.png';
import image2 from '../../assets/OBJECTS-2.png';
import image3 from '../../assets/Capa_2.png';

const Features = () => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const features = [
        ['Care ASAP, 24 / 7', 'On your phone or in your neighborhood, whenever you need it (like right now)', image1],
        ['Transparent Pricing', 'No extra charges clear pricing from the start.', image2],
        ['Quick and easy', 'Book care for you & your family in just a few taps.', image3],
    ];
    return (
        <SectionWrapper backgroundColor={'#662C8F'}>
            <Typography variant='h3' fontWeight={'bold'} color={'white'} textAlign={'center'}>Get care now, on your phone or in person</Typography>
            <SizedBox height={'2rem'}/>
            <Grid container direction={'row'}>
                {features.map((item, index) => (
                    <Grid item lg={4} sm={12} xs={12} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: isLargeScreen && '2rem'}}>
                        {/* <div style={{display: 'flex', width: '20rem', height: '12rem', backgroundColor: '#C9D5EF', padding: '20px', justifyContent: 'center', alignItems: 'center', borderRadius: '12rem / 7.6rem', WebkitBorderRadius: '12rem / 7.6rem', MozBorderRadius: '12rem / 7.6rem'}}> */}
                        <div style={isSmallScreen ? {display: 'flex', width: '10rem', height: '6rem', backgroundColor: '#C9D5EF', padding: '10px', justifyContent: 'center', alignItems: 'center', borderRadius: '6rem / 3.8rem', WebkitBorderRadius: '6rem / 3.8rem', MozBorderRadius: '6rem / 3.8rem'} : {display: 'flex', width: '20rem', height: '12rem', backgroundColor: '#C9D5EF', padding: '20px', justifyContent: 'center', alignItems: 'center', borderRadius: '12rem / 7.6rem', WebkitBorderRadius: '12rem / 7.6rem', MozBorderRadius: '12rem / 7.6rem'}}>
                            <img src={item[2]} width={'80%'} height={'80%'} style={{objectFit: 'contain'}}/>
                        </div>
                        <SizedBox height={'1rem'}/>
                        <Typography variant='h6' fontWeight={'bold'} color={'white'} gutterBottom>{item[0]}</Typography>
                        <Typography variant='body1' color={'#ddd'} textAlign={'center'}>{item[1]}</Typography>
                    </Grid>
                ))}
                <Grid item lg={4} sm={12} xs={12}>

                </Grid>
            </Grid>
        </SectionWrapper>
    )
}

export default Features
