import React, { useState } from 'react';
import './selectDateTime.css';
import { Footer, Navbar } from '../../sections';
import { Heading, SectionWrapper } from '../../components';
// import ResponsiveAppBar from '../../sections/app-bar/AppBar';
import { Button, Container, DialogContent, Grid } from '@mui/material';
import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { mainThemeColor } from '../../shared/constants';


const SelectDateTime = () => {
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [selectedTime, setSelectedTime] = useState(dayjs().set('hour', 10).set('minute', 0));
    const [selectedTimeString, setSelectedTimeString] = useState('10:00');
    const [availableSlots, setAvailableSlots] = useState(['10:00', '10:10', '10:20']);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [appointmentBookedSuccessfully, setAppointmentBookedSuccessfully] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogContent, setConfirmationDialogContent] = useState('');
    const userData = useSelector((states) => states.user.value);
    const navigate = useNavigate();
    const location = useLocation();



    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (appointmentBookedSuccessfully) {
            navigate('/user-dashboard');
        }
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };





    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
        const timeString = newTime.format('HH:mm');
        console.log(timeString);
        setSelectedTimeString(timeString);
        timeOptions.forEach((option) => {
            if (option.value === timeString) {
                console.log(option);
                setAvailableSlots(option.slots);
            }
        });
    };

    const handleSlotChange = (slot) => {
        console.log("Slot : " + slot);
        setSelectedSlot(slot);
    };

    const timeOptions = [
        { value: '09:00', label: '09:00', slots: ['09:00', '09:10', '09:20'] },
        { value: '09:30', label: '09:30', slots: ['09:30', '09:40', '09:50'] },
        { value: '10:00', label: '10:00', slots: ['10:00', '10:10', '10:20'] },
        { value: '10:30', label: '10:30', slots: ['10:30', '10:40', '10:50'] },
        { value: '11:00', label: '11:00', slots: ['11:00', '11:10', '11:20'] },
        { value: '11:30', label: '11:30', slots: ['11:30', '11:40', '11:50'] },
        { value: '12:00', label: '12:00', slots: ['12:00', '12:10', '12:20'] },
        { value: '12:30', label: '12:30', slots: ['12:30', '12:40', '12:50'] },
        { value: '13:00', label: '13:00', slots: ['13:00', '13:10', '13:20'] },
        { value: '13:30', label: '13:30', slots: ['13:30', '13:40', '13:50'] },
        { value: '14:00', label: '14:00', slots: ['14:00', '14:10', '14:20'] },
        { value: '14:30', label: '14:30', slots: ['14:30', '14:40', '14:50'] },
        { value: '15:00', label: '15:00', slots: ['15:00', '15:10', '15:20'] },
        { value: '15:30', label: '15:30', slots: ['15:30', '15:40', '15:50'] },
        { value: '16:00', label: '16:00', slots: ['16:00', '16:10', '16:20'] },
        { value: '16:30', label: '16:30', slots: ['16:30', '16:40', '16:50'] },
        { value: '17:00', label: '17:00', slots: ['17:00', '17:10', '17:20'] },
        { value: '17:30', label: '17:30', slots: ['17:30', '17:40', '17:50'] },
        { value: '18:00', label: '18:00', slots: ['18:00', '18:10', '18:20'] },
        { value: '18:30', label: '18:30', slots: ['18:30', '18:40', '18:50'] },
        { value: '19:00', label: '19:00', slots: ['19:00', '19:10', '19:20'] },
        { value: '19:30', label: '19:30', slots: ['19:30', '19:40', '19:50'] },
        { value: '20:00', label: '20:00', slots: ['20:00', '20:10', '20:20'] },
    ];


    const handleBookAppointment = async () => {
        const querySnapshot = await getDocs(collection(db, 'doctors'));
        var selectedDoctorId = '';
        var selectedDoctorName = '';
        var selectedDoctorDateTimes = [];

        if (selectedDate.format('DD-MM-YYYY').length > 0 && selectedTimeString.length > 0 && selectedSlot.length > 0) {
            querySnapshot.forEach((doc) => {
                var doctorAvailable = true;
                const time = doc.data().scheduledAppointmentDateTimes;
                if (time !== undefined) {
                    time.forEach((t) => {
                        if (t.date.localeCompare(selectedDate.format('DD-MM-YYYY'), undefined, { sensitivity: 'base' }) == 0 && t.slot.localeCompare(selectedSlot, undefined, { sensitivity: 'base' }) == 0) {
                            console.log('doctor not available');
                            doctorAvailable = false;
                        }
                    });
                    if (doctorAvailable === true) {
                        console.log('doctor available');
                        selectedDoctorId = doc.data().emailId;
                        selectedDoctorName = doc.data().firstName + ' ' + doc.data().lastName;
                        selectedDoctorDateTimes = time;
                    }
                }
            });
            if (selectedDoctorId !== '') {
                console.log(userData.uid.length);
                if (userData.uid.length > 0) {
                    const d = selectedDate.format('DD-MM-YYYY');
                    const collectionRef = collection(db, 'scheduled-appointments',);
                    addDoc(collectionRef, {
                        userId: userData.uid,
                        userName: `${userData.firstName} ${userData.lastName}`,
                        userEmail: userData.emailId,
                        userPhoneNumber: userData.phoneNumber,
                        consultationType: 'Free Consultation',
                        scheduledTime: {
                            date: d,
                            time: selectedTimeString,
                            slot: selectedSlot,
                        },
                        doctorId: selectedDoctorId,
                        doctorName: selectedDoctorName,
                        status: 'Scheduled',
                        paymentInformation: {
                            completed: true,
                            gateway: 'razorpay',
                            totalAmount: 0,
                            gatewayInformation: {
                                orderId: 'order-id',
                            }
                        },
                        meetingId: '',
                        doctorJoined: false,
                        userJoined: false,
                    }).then((response) => {
                        console.log("Appointment Created... " + response);
                        const updatedDateTimes = [...selectedDoctorDateTimes, { date: selectedDate.format('DD-MM-YYYY'), time: selectedTimeString, slot: selectedSlot }]
                        updateDoc(doc(db, 'doctors', selectedDoctorId), {
                            scheduledAppointmentDateTimes: updatedDateTimes,
                        }).then((response) => {
                            setAppointmentBookedSuccessfully(true);
                            setDialogTitle('Appointment Booked Successfully!');
                            setDialogContent('Date: ' + selectedDate.format('DD-MM-YYYY') + ', Slot: ' + selectedSlot);
                            handleClickOpen();
                        }).catch((error) => {
                            console.log(error);
                        });
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    console.log('User not logged in');
                    navigate('/user-login', { state: { openLogin: true, navpath: '/consult-doctor' } });
                }
            } else {
                console.log('No doctor available');
                setDialogTitle('No doctor available');
                setDialogContent('Please select a different time slot.');
                handleClickOpen();
                return;
            }
        }
        setConfirmationDialogOpen(false);
    };



    return (
        <div className="consult_doctor__container">
            <Navbar />
            <SectionWrapper increasePaddingTop={true} backgroundColor={'#FAF3FF'}>
                <Container maxWidth={'md'}>
                    <Heading text={"Select date and time"} />
                    <div className="consult_doctor__form-container">
                        <div className="consult_doctor__form-group">
                            <label htmlFor="date">Select Date:</label>
                            <DatePicker
                                value={selectedDate}
                                onChange={(newDate) => handleDateChange(newDate)}
                                format='DD/MM/YYYY'
                                minDate={dayjs(new Date())}
                                maxDate={dayjs(new Date()).add(7, 'day')}
                            />
                        </div>
                        <div className="consult_doctor__form-group">
                            <label htmlFor="time">Select Time:</label>
                            <TimePicker
                                value={selectedTime}
                                onChange={(newTime) => handleTimeChange(newTime)}
                                timeSteps={{ hours: 1, minutes: 30 }}
                                minTime={dayjs(new Date().setHours(9, 0, 0))}
                                maxTime={dayjs(new Date().setHours(20, 0, 0))}
                            />
                        </div>
                        <div className="consult_doctor__form-group">
                            {availableSlots.length > 0 && <div className="time-slots">
                                <label htmlFor="time">Available Slots</label>
                                <div className="time-slots-row">
                                    {availableSlots.map((slot) => (
                                        <div
                                            key={slot}
                                            className={`time-slot ${slot === selectedSlot ? 'selected' : ''
                                                }`}
                                            onClick={() => handleSlotChange(slot)}
                                        >
                                            {slot}
                                        </div>
                                    ))}
                                </div>
                            </div>}
                            <div className="consult_doctor__form-group">
                                <Button
                                    variant='contained'
                                    // disableElevation
                                    // color='#662C8F'
                                    sx={{ backgroundColor: '#fff', color: mainThemeColor, textTransform: 'none', fontWeight: 'bold', ':hover': { backgroundColor: '#eee' }, width: 'fit-content' }}
                                    onClick={() => {
                                        // setConfirmationDialogContent(`Are you sure you want to book an appointment on ${selectedDate.format('DD-MM-YYYY')} at ${selectedTimeString}?`);
                                        // setConfirmationDialogOpen(true);
                                        if (selectedDate.format('DD-MM-YYYY').length > 0 && selectedTimeString.length > 0 && selectedSlot.length > 0) {
                                            const d = selectedDate.format('DD-MM-YYYY');
                                            navigate('/book-lab-test/payment', {
                                                state: {
                                                    address: {
                                                        address: location.state.address,
                                                        city: location.state.city,
                                                        id: location.state.id,
                                                        name: location.state.name,
                                                        pincode: location.state.pincode,
                                                        state: location.state.state,
                                                    },
                                                    dateTime: {
                                                        date: d,
                                                        time: selectedTimeString,
                                                        slot: selectedSlot,
                                                    }
                                                }
                                            });
                                        }

                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>

                {/* <div className='consullt_doctor__content'>
                    <div className="consult_doctor__header">

                    </div>
                    <Grid container direction={'row'} alignItems={'center'}>
                        <Grid item md={6} sm={12}>
                            <img
                                src="https://img.freepik.com/free-vector/online-doctor-flat-design_23-2148521415.jpg?w=1800&t=st=1683817666~exp=1683818266~hmac=b089a8ffe0d9834b4c66a84b37641c62c0a0861edce14ce6ac2ea3c14b979f1c"
                                alt="doctor"
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>

                        </Grid>
                    </Grid>
                </div> */}
            </SectionWrapper>
            <Footer />
        </div>
    );
}

export default SelectDateTime
