import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from '../../sections';
import { Button, Container, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Timestamp, addDoc, collection, doc } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { emptyCart } from '../../features/cart';
import { Heading, SectionWrapper, SizedBox } from '../../components';
import { mainThemeColor } from '../../shared/constants';

const Payment = () => {
    const navigate = useNavigate();
    const userDetails = useSelector((states) => states.user.value);
    const cart = useSelector((states) => states.cart.value);
    const totalAmount = useSelector((states) => states.cart.totalAmount);
    const location = useLocation();
    const dispatch = useDispatch();

    const [totalPrice, setTotalPrice] = useState('');
    const [loading, setLoading] = useState(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [packageName, setPackageName] = useState('');
    const [packageDetails, setPackageDetails] = useState({});
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDetailsDialogOpen = (packageName, packageDetails) => {
        setPackageName(packageName);
        setPackageDetails(packageDetails);
        setDetailsDialogOpen(true);
        console.log("Package Details : " + packageDetails);
    };
    const handleDetailsDialogClose = (value) => {
        setDetailsDialogOpen(false);
    };

    const handleBookAppointment = async () => {
        if (location.state.address !== undefined) {
            const collectionRef = collection(db, 'diagnostics_appointments');
            await addDoc(collectionRef, {
                userId: userDetails.uid,
                userName: userDetails.firstName + ' ' + userDetails.lastName,
                userEmail: userDetails.emailId,
                userPhoneNumber: userDetails.phoneNumber,
                userAddress: location.state.address.address,
                cart: cart,
                numberOfItems: Object.keys(cart).length,
                bookingStatus: {
                    status: 'Booked',
                    dateBooked: Timestamp.fromDate(new Date()),
                },
                dateTime: location.state.dateTime,
                paymentDetails: {
                    totalAmount: totalAmount,
                    paymentStatus: 'completed',
                    paymentGateway: 'razorpay',
                    gatewayInformation: {
                        razorpayPaymentId: '',
                        razorpayOrderId: '',
                        razorpaySignature: '',
                    }
                },
            }).then((response) => {
                console.log(response);
                dispatch(emptyCart());
                navigate('/user-dashboard/appointments');
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    useEffect(() => {
        console.log(location.state.address);
    });

    return (
        <div>
            <Navbar />
            <SectionWrapper increasePaddingTop={true} backgroundColor={'#FAF3FF'}>
                <Container maxWidth={'lg'}>
                    <Typography variant='h4' fontWeight={'bold'} gutterBottom sx={{ color: mainThemeColor }}>Payment</Typography>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '15px', overflow: 'hidden' }}>
                        <ListItem>
                            <Typography variant='h6'>Cart</Typography>
                        </ListItem>
                        {cart !== undefined && Object.keys(cart).length > 0 && Object.keys(cart).map((packageKey) => (
                            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', backgroundColor: '#fff' }}>
                                <ListItemButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', width: '100%' }}
                                    onClick={() => {
                                        handleDetailsDialogOpen(
                                            packageKey,
                                            cart[packageKey],
                                        )
                                    }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <ListItemText
                                                primary={packageKey}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                                            <Typography variant='body2' color={'#111'} textAlign={'end'} gutterBottom>{`${cart[packageKey]['tests'].length} tests`}</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                                            <Typography variant='body2' color={'#111'} textAlign={'end'}>{`₹ ${cart[packageKey]['price']}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                                            <Typography fontWeight={'bold'}>Quantity: {cart[packageKey]['numberInCart']}</Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                                <Divider style={{ width: '100%' }} />
                            </ListItem>
                        ))}
                    </List>

                    <SizedBox height={'2rem'} />

                    <Container sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '15px', overflow: 'hidden', p: '1rem 0 1rem 0', pl: isSmallScreen ? '1rem' : '0', pr: isSmallScreen ? '1rem' : '0' }}>
                        <Typography variant='h6' gutterBottom>Selected Date and Time</Typography>
                        <Typography variant='body1'>Date: {location.state.dateTime.date}</Typography>
                        <Typography variant='body1'>Time: {location.state.dateTime.slot}</Typography>
                    </Container>

                    <SizedBox height={'2rem'} />

                    <Container sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '15px', overflow: 'hidden', p: '1rem 0 1rem 0', pl: isSmallScreen ? '1rem' : '0', pr: isSmallScreen ? '1rem' : '0' }}>
                        <Typography variant='h6' gutterBottom>Address</Typography>
                        <Typography variant='body1'>Name: {location.state.address.address.name}</Typography>
                        <Typography variant='body1'>Address: {location.state.address.address.address}</Typography>
                        <Typography variant='body1'>City: {location.state.address.address.city}</Typography>
                        <Typography variant='body1'>State: {location.state.address.address.state}</Typography>
                        <Typography variant='body1'>Pincode: {location.state.address.address.pincode}</Typography>
                    </Container>

                    <SizedBox height={'2rem'} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button variant='contained'
                            sx={{ backgroundColor: '#fff', color: mainThemeColor, textTransform: 'none', fontWeight: 'bold', ':hover': { backgroundColor: '#eee' } }}
                            onClick={handleBookAppointment}
                        >
                            Pay and Book Appointment
                        </Button>
                        <SizedBox width={'1rem'}/>
                        <Typography fontWeight={'bold'}>Total Amount : ₹ {totalAmount}</Typography>
                    </div>
                </Container>
            </SectionWrapper>
            <Footer />
        </div>
    )
}

export default Payment
