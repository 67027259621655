import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-setup/firebase';

class LabTestPackageClass {
    constructor(labTestPackages) {
        this.labTestPackages = labTestPackages;
    }

    setLabTestPackages(labTestPackages) {
        this.labTestPackages = labTestPackages;
    }

    async getLabTestPackages() {
        if (this.labTestPackages.length > 0) {
            return this.labTestPackages;
        } else {
            console.log("Fetching lab test packages from firestore");
            var packages = {};
            const getPackages = async () => {
                const snapshot = await getDoc(doc(db, "Lab_Tests", "All_Lab_Tests"));
                console.log(Object.keys(snapshot.data()).length);
                for(let i = 0; i < Object.keys(snapshot.data()).length; i++) {
                    packages[Object.keys(snapshot.data())[i]] = Object.values(snapshot.data())[i]
                }
            };
            await getPackages();
            this.labTestPackages = packages;
            return packages;
        }
    }
}

export var LabTestPackages = new LabTestPackageClass({});