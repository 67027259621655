import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AboutUs, AccountDetails, AllBlogs, Appointments, BlogScreen, BookAppointment, Home, PageNotFound, Payment, SelectAddress, SelectDateTime, UserDashboard, UserLogin } from './pages';
import ScrollToTop from './services/scroll-to-top';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/all-blogs' element={<AllBlogs />} />
        {/* <Route path='/blog' element={<BlogScreen />} /> */}
        <Route path='/login' element={<UserLogin />} />
        <Route path='/user-dashboard' element={<UserDashboard />} />
        <Route path='/book-lab-test/select-package' element={<BookAppointment />} />
        <Route path='/book-lab-test/select-address' element={<SelectAddress />} />
        <Route path='/account-details' element={<AccountDetails />} />
        <Route path='/book-lab-test/payment' element={<Payment />} />
        <Route path='/user-dashboard/appointments' element={<Appointments />} />
        <Route path='/book-lab-test/select-date-time' element={<SelectDateTime />} />
        <Route path='/*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
