import React from 'react'
import { mainThemeColor } from '../../shared/constants'
import { Typography } from '@mui/material'

const Heading = ({text}) => {
    return (
        <div>
            <Typography variant='h4' fontWeight={'bold'} gutterBottom color={mainThemeColor}>{text !== undefined ? text : ""}</Typography>
        </div>
    )
}

export default Heading
