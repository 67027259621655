import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../firebase-setup/firebase';
import { Footer, Navbar } from '../../sections';
import { Heading, SectionWrapper, SizedBox } from '../../components';
import { Button, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import { mainThemeColor } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';

const Appointments = () => {

    const [appointments, setAppointments] = useState([]);
    const [fetchedAppointments, setFetchedAppointments] = useState(false);
    const userData = useSelector((states) => states.user.value);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const handleBookLabTest = () => {
        if (userData.isLoggedIn) {
            navigate('/book-lab-test/select-package');
        } else {
            navigate('/login', {
                state: {
                    navpath: '/book-lab-test/select-package'
                }
            });
        }

    };

    useEffect(() => {
        const getAppointments = async () => {
            const q = query(collection(db, 'diagnostics_appointments'), where('userEmail', '==', userData.emailId));
            const querySnapshot = await getDocs(q);
            var o = [];
            var i = 0;
            querySnapshot.forEach((doc) => {
                o = [...o, { ...doc.data(), index: i }];
                i++;
            });
            setAppointments(o);
        };
        if (!fetchedAppointments) {
            getAppointments().then(() => {
                setFetchedAppointments(true);
            }).catch((error) => {
                console.log(error);
            })
        }
    }, []);

    return (
        <div>
            <Navbar />
            <SectionWrapper increasePaddingTop={true}>
                <Heading text={'Lab Test Appointments'} />
                <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '15px', overflow: 'hidden' }}>
                    {appointments !== undefined && appointments.length > 0 ? (appointments.map((appointment) => (
                        <ListItem key={appointment.bookingStatus.dateBooked.toDate().toString()} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', backgroundColor: '#fff' }}>
                            <ListItemButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <ListItemText
                                            primary={appointment.bookingStatus.dateBooked.toDate().toLocaleDateString("en-US", options)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                                        <Typography variant='body2' color={'#666'} textAlign={'end'} gutterBottom>{`${appointment.numberOfItems} Packages`}</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                                        <Typography variant='body2' color={'#666'} textAlign={'end'}>{`₹ ${appointment.paymentDetails.totalAmount}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                                        <Typography variant='body2' color={'#666'} textAlign={'end'}>{`Status: ${appointment.bookingStatus.status}`}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                            <Divider style={{ width: '100%' }} />
                        </ListItem>
                    ))) :
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                            <SizedBox height={'2rem'} />
                            <Typography variant='h5' sx={{ color: '#111' }}>No appointments available</Typography>
                            <SizedBox height={'2rem'} />
                            <Button variant='contained'
                                sx={{ maxWidth: '20rem', width: '100%', borderRadius: '10rem', backgroundColor: '#fff', color: mainThemeColor, textTransform: 'none', fontWeight: 'bold', ':hover': { backgroundColor: '#ccc' } }}
                                onClick={handleBookLabTest}
                            >
                                Book Lab test
                            </Button>
                        </div>
                    }
                </List>
            </SectionWrapper>
            <Footer />
        </div>
    )
}

export default Appointments
