import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        isLoggedIn: false,
        uid: '',
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNumber: '',
        scheduledAppointments: [],
        appointmentHistory: [],
        addresses: [],
        totalAddresses: 0,
    }
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            state.value = action.payload;
        },
        logout: (state, action) => {
            state.value = initialState.value;
        },
        addAddress: (state, action) => {
            state.value.addresses = [...state.value.addresses, action.payload];
        },
        removeAddress: (state, action) => {
            if (state.value.addresses.length > 0) {
                const updatedAddresses = [...state.value.addresses];
                var removeAddressIndex = 0;
                for (let i = 0; i < state.value.addresses.length; i++) {
                    if (state.value.addresses[i].id === action.payload.addressId) {
                        removeAddressIndex = i;
                    } else if (action.payload.addressId < state.value.addresses[i].id) {
                        updatedAddresses[i].id = state.value.addresses[i].id - 1;
                    }
                }
                if (removeAddressIndex >= 0) {
                    updatedAddresses.splice(removeAddressIndex, 1);
                    state.value.addresses = updatedAddresses;
                    state.value.totalAddresses = state.value.totalAddresses - 1;
                }

            }
        },
        updateBasicInfo: (state, action) => {
            state.value.firstName = action.payload.firstName;
            state.value.lastName = action.payload.lastName;
            state.value.phoneNumber = action.payload.phoneNumber;
        },
        setAddresses: (state, action) => {
            state.value.addresses = action.payload.updatedAddresses;
        },

    },
});

export const { login, logout, addAddress, removeAddress, updateBasicInfo, setAddresses } = userSlice.actions;
export default userSlice.reducer;