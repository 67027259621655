import React from 'react';
import { SectionWrapper, SizedBox } from '../../components';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import image1 from '../../assets/Frame-1.png';
import image2 from '../../assets/Frame.png';
import { mainThemeColor } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Header = () => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const user = useSelector((states) => states.user.value);

    const handleBookLabTest = () => {
        if (user.isLoggedIn) {
            navigate('/book-lab-test/select-package');
        } else {
            navigate('/login', {
                state: {
                    navpath: '/book-lab-test/select-package'
                }
            });
        }

    };

    return (
        <SectionWrapper backgroundColor={mainThemeColor} increasePaddingTop={true} removePaddingBottom={true}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                <Typography color={'white'} textAlign={'center'} variant={isSmallScreen ? 'h4' : 'h2'} fontWeight={'bold'}>Cartula Diagnostics</Typography>
                <Typography color={'white'} textAlign={'center'} variant={isSmallScreen ? 'h6' : 'h5'} fontWeight={'bold'} gutterBottom>Your Health, Your Choice</Typography>
                {/* <Typography color={'white'} textAlign={'center'} variant='h2' fontWeight={'bold'}>Whether in the clinic or your own home</Typography> */}
                <Typography color={'white'} textAlign={'center'} fontSize={isSmallScreen ? '16px' : '20px'} gutterBottom>Get insights about your health quickly and from the comfort of your home</Typography>
                <SizedBox height={'1rem'} />
                <Button variant='contained'
                    sx={{ maxWidth: '20rem', width: '100%', borderRadius: '10rem', backgroundColor: '#fff', color: '#111', textTransform: 'none', fontWeight: 'bold', ':hover': { backgroundColor: '#ccc' } }}
                    onClick={handleBookLabTest}
                >
                    Book Lab test
                </Button>
                <SizedBox height={'4rem'} />
                <Grid container direction={'row'}>
                    <Grid item md={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                        <img src={image1} width={'100%'} height={'100%'} style={{ objectFit: 'contain', aspectRatio: '3 / 2' }} />
                    </Grid>
                    {!isLargeScreen && <Grid item md={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                        <img src={image2} width={'100%'} height={'100%'} style={{ objectFit: 'contain', aspectRatio: '3 / 2' }} />
                    </Grid>}
                </Grid>
            </div>

            {/* <div style={{height: '30rem'}}></div> */}
        </SectionWrapper>
    )
}

export default Header