import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {},
    totalAmount: 0,
};

export const cartSlice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        addItemToCart: (state, action) => {
            console.log("adding element to cart");
            var i = 0;

            if (Object.keys(state.value).length > 0 && Object.keys(state.value).includes(action.payload["packageName"])) {
                state.value[action.payload["packageName"]]["numberInCart"] = state.value[action.payload["packageName"]]["numberInCart"] + 1;
            } else {
                console.log("adding element to cart");
                state.value[action.payload["packageName"]] = { ...action.payload, "numberInCart": 1 };
            }
            state.totalAmount = state.totalAmount + action.payload["price"];
        },
        subtractItemFromCart: (state, action) => {
            if (Object.keys(state.value).includes(action.payload["packageName"]) && state.value[action.payload["packageName"]]["numberInCart"] > 1) {
                state.value[action.payload["packageName"]]["numberInCart"] = state.value[action.payload["packageName"]]["numberInCart"] - 1;

            } else {
                delete state.value[action.payload["packageName"]];
            }
            state.totalAmount = state.totalAmount - action.payload["price"];
        },
        removeItemFromCart: (state, action) => {
            delete state.value[action.payload["packageName"]];
            state.totalAmount = state.totalAmount - action.payload["price"];
        },
        emptyCart: (state, action) => {
            state.value = {};
            state.totalAmount = 0;
        },
    },
});

export const { addItemToCart, subtractItemFromCart, removeItemFromCart, emptyCart } = cartSlice.actions;
export default cartSlice.reducer;