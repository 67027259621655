import { Container } from '@mui/material';
import React from 'react';

const SectionWrapper = ({
    id,
    backgroundColor,
    increasePaddingTop,
    increasePaddingBottom,
    removePaddingTop,
    removePaddingBottom,
    children
}) => {
    return (
        <div id={id} style={{ padding: `${increasePaddingTop ? '4rem' : '0'} 0 ${increasePaddingBottom ? '4rem' : 0} 0`, backgroundColor: backgroundColor ?? 'white' }}>
            <div style={{ padding: '0 0 0 0', backgroundColor: backgroundColor ?? 'white' }}>
                <Container maxWidth={'xl'} sx={{ pt: removePaddingTop ? '0rem' : '4rem', pb: removePaddingBottom ? '0rem' : '4rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {children}
                </Container>
            </div>
        </div>
    )
}

export default SectionWrapper
