import React from "react";
import { SectionWrapper, SizedBox } from "../../components";
import { Button, Container, Typography } from "@mui/material";
import logo from "../../assets/cartula-diagnostics-logo-with-text.jpg";
import { Facebook, Twitter, LinkedIn, Instagram, Pinterest } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <SectionWrapper>
      <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img
          src={logo}
          style={{ width: "100%", height: "100%", maxWidth: "200px", objectFit: "contain", borderRadius: "20px" }}
        />
        {/* <SizedBox height={'8px'} /> */}
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => {
              navigate("/");
            }}
            sx={{ color: "#444", textTransform: "none", fontSize: "16px" }}
          >
            Home
          </Button>
          <Button
            onClick={() => {
              navigate("/about-us");
            }}
            sx={{ color: "#444", textTransform: "none", fontSize: "16px" }}
          >
            About Us
          </Button>
        </div>
        <SizedBox height={"20px"} />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <a href="https://www.facebook.com/cartuladiagnostics" target="_blank" rel="noopener noreferrer">
            <Facebook />
          </a>
          <SizedBox width={"8px"} />
          <a href="https://twitter.com/CartulaLab" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </a>
          <SizedBox width={"8px"} />

          <a
            href="https://www.linkedin.com/in/cartula-diagnostics-124968300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn />
          </a>
          <SizedBox width={"8px"} />
          <a href="https://www.pinterest.com/cartuladiagnostics/" target="_blank" rel="noopener noreferrer">
            <Pinterest />
          </a>
        </div>
        <SizedBox height={"2rem"} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography textAlign={"center"}>
            © 2024 All rights reserved. Cartula Diagnostics. A unit of Arjava Dx Pvt. Ltd.
          </Typography>
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default Footer;
