import React from 'react'
import { Footer, Navbar, SelectLabTestPackage } from '../../sections'

const BookAppointment = () => {
  return (
    <div>
      <Navbar />
      <SelectLabTestPackage />
      <Footer />
    </div>
  )
}

export default BookAppointment
