import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Alert, Collapse, Container, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logoImage from '../../assets/cartula_diagnostics_logo.png';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import PersonAdd from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ListItemIcon from '@mui/material/ListItemIcon';
import { logout } from '../../features/user';
import { getAuth, signOut } from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../features/alert';
import { mainThemeColor } from '../../shared/constants';

const drawerWidth = 240;

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useSelector((states) => states.user.value.isLoggedIn);
  const alert = useSelector((states) => states.alert.value);
  const dispatch = useDispatch();
  const user = useSelector((states) => states.user.value);

  const navItems = {
    'Home': '/',
    'Book Lab Test': user.isLoggedIn ? '/book-lab-test/select-package' : '/login',
    'About Us': '/about-us',
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const open = Boolean(anchorElUser);

  const handleOpenNavMenu = (event) => {
    console.log(event.currentTarget);
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={logoImage} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
      <Divider />
      <List>
        {Object.keys(navItems).map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton onClick={() => { navigate(navItems[item]) }} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar elevation={2} component="nav" sx={{ backgroundColor: 'white', color: 'black' }}>
        <Container maxWidth={'xl'}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logoImage} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
            <div style={{ flexGrow: 1 }}></div>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              MUI
            </Typography> */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {Object.keys(navItems).map((item) => (
                <Button key={item} onClick={() => { navigate(navItems[item]) }} sx={{ color: '#662C8F', textTransform: 'none', fontSize: '16px' }}>
                  {item}
                </Button>
              ))}
            </Box>

            <IconButton color="primary" sx={{ ml: 2 }} aria-label="add to shopping cart" onClick={
              // () => { isLoggedIn ? navigate('/user-dashboard') : navigate('/user-login') }}
              handleOpenNavMenu
            }>
              <PersonIcon sx={{ color: mainThemeColor }} />
            </IconButton>

            <Menu
              anchorEl={anchorElNav}
              id="account-menu"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              onClick={handleCloseNavMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {isLoggedIn && <MenuItem onClick={(e) => {
                navigate('/user-dashboard');
                setAnchorElNav(null);
              }}>
                <Avatar /> My account
              </MenuItem>}
              {isLoggedIn && <Divider />}
              {isLoggedIn && <MenuItem onClick={(e) => {
                const auth = getAuth();
                signOut(auth).then(() => {
                  console.log('Signed out successfully');
                  dispatch(logout({ isLoggedIn: false }));
                  navigate('/');
                  dispatch(setAlert({
                    isOpen: true,
                    alertText: 'Logged out successfully',
                    alertDuration: 2000,
                    alertSeverity: 'success',
                  }));
                }).catch((error) => {
                  console.log('Could not sign out : ' + error);
                  dispatch(setAlert({
                    isOpen: true,
                    alertText: 'Some error occurred while logging out',
                    alertDuration: 2000,
                    alertSeverity: 'error',
                  }));
                });
                navigate('/');
                setAnchorElNav(null);
              }}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>}
              {!isLoggedIn && <MenuItem onClick={(e) => {
                navigate('/login', { state: { openLogin: true } });
                setAnchorElNav(null);
              }}>
                <ListItemIcon>
                  <LoginIcon fontSize="small" />
                </ListItemIcon>
                Login
              </MenuItem>}
              {!isLoggedIn && <MenuItem onClick={(e) => {
                navigate('/login', { state: { openLogin: false } });
                setAnchorElNav(null);
              }}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Sign up
              </MenuItem>}
            </Menu>

          </Toolbar>
        </Container>

        <Collapse in={alert.isOpen}>
          <div style={{ height: 'fit-content', marginTop: '1rem' }}>
            <Alert
              variant="filled"
              severity={alert.alertSeverity}
              sx={{ width: '100%' }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(setAlert({
                      isOpen: false,
                      alertText: alert.alertText,
                      alertDuration: alert.alertDuration,
                      alertSeverity: alert.alertSeverity,
                    }));
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {alert.alertText}
            </Alert>
          </div>
        </Collapse>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Navbar;
