import React, { useEffect, useState } from 'react';
import { AppBar, Button, Card, CardActionArea, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { Heading, SectionWrapper } from '../../components';
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, runTransaction } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import { addAddress, removeAddress, setAddresses, updateBasicInfo } from '../../features/user';
import { Footer, Navbar } from '../../sections';
import { mainThemeColor } from '../../shared/constants';

const SelectAddress = () => {

    function AddressDialog({ isAdd, addressDetails }) {
        const Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });

        const styles = {
            root: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: 'center',
                height: '100vh',
                padding: '24px',
                // backgroundColor: '#f0f2f5',
            },
            form: {
                width: '100%',
                marginBottom: '16px',
            },
            input: {
                backgroundColor: '#fff',
            },
            button: {
                width: '100%',
            },
            featureContainer: {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '24px',
            },
            feature: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '24px',
            },
            featureTitle: {
                fontSize: '18px',
                marginBottom: '8px',
                fontWeight: 'bold',
            },
            featureDescription: {
                fontSize: '14px',
                textAlign: 'center',
            },
        };

        const [name, setName] = useState('');
        const [address, setAddress] = useState('');
        const [pincode, setPincode] = useState('');
        const [city, setCity] = useState('');
        const [state, setState] = useState('');
        const [fetchedAddressDetails, setFetchedAddressDetails] = useState(false);

        const navigate = useNavigate();
        const dispatch = useDispatch();
        const location = useLocation();
        const userData = useSelector((states) => states.user.value);

        const handlePincodeChange = async (e) => {
            const enteredPincode = e.target.value;
            setPincode(enteredPincode);

            if (enteredPincode.length === 6) {
                // try {
                //     const response = await fetch(`http://postalpincode.in/api/pincode/${enteredPincode}`);
                //     const data = await response.json();
                //     if (data && data[0].PostOffice) {
                //         const { District, State } = data[0].PostOffice[0];
                //         setCity(District);
                //         setState(State);
                //     }
                // } catch (error) {
                //     console.log('Error fetching pincode details:', error);
                // }
            } else {
                // setCity('');
                // setState('');
            }
        };

        const handleSaveInfo = async () => {
            await runTransaction(db, async (transaction) => {
                console.log('User Data : ' + userData);
                console.log('UID : ' + userData.uid);
                const sfDocRef = doc(db, 'diagnostics_users', userData.uid);
                const sfDoc = await transaction.get(sfDocRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }

                if (isAdd) {
                    const totalAddresses = sfDoc.data().totalAddresses;
                    const newAddress = {
                        name: name,
                        address: address,
                        pincode: pincode,
                        city: city,
                        state: state,
                        id: totalAddresses,
                    };
                    const updatedAddresses = [...sfDoc.data().addresses, newAddress];
                    const updatedTotalAddresses = totalAddresses + 1;
                    transaction.update(sfDocRef, { addresses: updatedAddresses, totalAddresses: updatedTotalAddresses });
                    dispatch(addAddress(newAddress));
                } else {
                    const updatedAddresses = [...sfDoc.data().addresses];
                    var j = 0;
                    [...sfDoc.data().addresses].forEach((addr) => {
                        if (addr.id === addressDetails.id) {
                            updatedAddresses[j].name = name;
                            updatedAddresses[j].address = address;
                            updatedAddresses[j].city = city;
                            updatedAddresses[j].state = state;
                            updatedAddresses[j].pincode = pincode;
                        }
                        j++;
                    });
                    transaction.update(sfDocRef, { addresses: updatedAddresses });
                    dispatch(setAddresses({ updatedAddresses }));
                }
                handleClose();
            });
        };


        useEffect(() => {
            if (!fetchedAddressDetails && !isAdd && Object.keys(addressDetails).length > 0) {
                setName(addressDetails.name);
                setAddress(addressDetails.address);
                setCity(addressDetails.city);
                setState(addressDetails.state);
                setPincode(addressDetails.pincode);
                setFetchedAddressDetails(true);
            }
        });


        return <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        // TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#fff', boxShadow: 'none' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        sx={{ color: '#111' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, color: '#111' }} variant="h6" component="div">
                        {isAdd ? 'Add Address' : 'Edit Address'}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={styles.root}>
                <Grid container spacing={2} maxWidth={'lg'} sx={styles.form}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            sx={styles.input}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            fullWidth
                            sx={styles.input}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Pincode"
                            value={pincode}
                            onChange={handlePincodeChange}
                            fullWidth
                            sx={styles.input}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            fullWidth
                            // disabled={pincode.length !== 6}
                            sx={styles.input}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            fullWidth
                            // disabled={pincode.length !== 6}
                            sx={styles.input}
                        />
                    </Grid>
                </Grid>

                {/* <div style={styles.featureContainer}>
                    <div style={styles.feature}>
                        <Typography variant="body2">
                            100% genuine products
                        </Typography>
                        <Typography variant="body2" style={styles.featureDescription}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                    </div>
                    <div style={styles.feature}>
                        <Typography variant="body2">
                            Free and fast delivery
                        </Typography>
                        <Typography variant="body2" style={styles.featureDescription}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                    </div>
                </div> */}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveInfo}
                    disableElevation
                    style={{ ...styles.button, maxWidth: '900px', borderRadius: '50px', height: '3rem', backgroundColor: mainThemeColor }}
                >
                    Save Address
                </Button>
            </div>
        </Dialog>
    }


    function DetailsSavedDialog() {
        return <Dialog
            open={detailsSavedDialogOpen}
            onClose={handleDetailsSavedDialogClose}
            aria-labelledby="alert-dialog-title-1"
            aria-describedby="alert-dialog-description-1"
        >
            {/* <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle> */}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {detailsSavedDialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDetailsSavedDialogClose} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    }




    const userData = useSelector((states) => states.user.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [detailsSavedDialogMessage, setDetailsSavedDialogMessage] = useState();
    const [open, setOpen] = React.useState(false);
    const [detailsSavedDialogOpen, setDetailsSavedDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [addressDetails, setAddressDetails] = useState({});
    const totalAmount = useSelector((states) => states.cart.totalAmount);

    // useEffect(() => {

    //     setFirstName(userData.firstName);
    //     setLastName(userData.lastName);
    //     setEmailId(userData.emailId);
    //     setPhoneNumber(userData.phoneNumber);
    // }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDetailsSavedDialogClose = () => {
        setDetailsSavedDialogOpen(false);
    };



    const handleRemoveAddress = async (addressId) => {
        await runTransaction(db, async (transaction) => {
            console.log('User Data : ' + userData);
            console.log('UID : ' + userData.uid);
            const sfDocRef = doc(db, 'users', userData.uid);
            const sfDoc = await transaction.get(sfDocRef);
            if (!sfDoc.exists()) {
                throw "Document does not exist!";
            }
            const totalAddresses = sfDoc.data().totalAddresses;
            const updatedAddresses = [...sfDoc.data().addresses];
            var index = 0;
            var removeIndex = -1;
            sfDoc.data().addresses.forEach((address) => {
                if (address.id === addressId) {
                    removeIndex = index;
                } else if (addressId < address.id) {
                    updatedAddresses[index].id = address.id - 1;
                }
                index++;
            });
            const updatedTotalAddresses = totalAddresses - 1;
            if (removeIndex >= 0) {
                updatedAddresses.splice(removeIndex, 1);
                transaction.update(sfDocRef, { addresses: updatedAddresses, totalAddresses: updatedTotalAddresses });
                dispatch(removeAddress({ addressId: addressId }));
            } else {
                transaction.update(sfDocRef, { addresses: [...sfDoc.data().addresses], totalAddresses: sfDoc.data().totalAddresses });
            }
        });
    };



    return (
        <div>
            <Navbar />
            {/* <MyStepper steps={['Cart', 'Address', 'Payment']} activeStep={1} type={1} /> */}
            <SectionWrapper increasePaddingTop={true} backgroundColor={'#FAF3FF'}>
                <Heading text={'Select Address'} weight={2} />

                <Grid container spacing={2}>
                    {userData.addresses.map((address) => <Grid key={address.id} item xs={12} md={4}>
                        <Card variant='outlined' sx={{ width: '100%', borderRadius: 5 }} >
                            <CardActionArea onClick={() => {
                                navigate('/book-lab-test/select-date-time', {
                                    state: {
                                        address: {
                                            address: address.address,
                                            city: address.city,
                                            id: address.id,
                                            name: address.name,
                                            pincode: address.pincode,
                                            state: address.state,
                                        },
                                    }
                                });
                            }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', minHeight: '15rem', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>{`Name: ${address.name}`}</Typography>
                                    <Typography>{`Address: ${address.address}`}</Typography>
                                    <Typography>{`City: ${address.city}`}</Typography>
                                    <Typography>{`State: ${address.state}`}</Typography>
                                    <Typography>{`Pincode: ${address.pincode}`}</Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Grid container spacing={2} mx={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant='contained'
                                            disableElevation
                                            fullWidth
                                            sx={{ borderRadius: 50, backgroundColor: mainThemeColor, ':hover': {backgroundColor: mainThemeColor} }}
                                            onClick={() => {
                                                navigate('/book-lab-test/select-date-time', {
                                                    state: {
                                                        address: {
                                                            address: address.address,
                                                            city: address.city,
                                                            id: address.id,
                                                            name: address.name,
                                                            pincode: address.pincode,
                                                            state: address.state,
                                                        },
                                                    }
                                                });
                                            }}
                                        >
                                            Select
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant='outlined'
                                            disableElevation
                                            fullWidth
                                            sx={{ borderRadius: 50, color: mainThemeColor, borderColor: mainThemeColor }}
                                            onClick={() => {
                                                setIsAdd(false);
                                                setAddressDetails(address);
                                                handleClickOpen();
                                            }}
                                        >Edit</Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>)}
                    <Grid item xs={12} md={4}>
                        <Card variant='outlined' sx={{ width: '100%', borderRadius: 5 }} onClick={() => {
                            setIsAdd(true);
                            setAddressDetails({});
                            handleClickOpen();
                        }}>
                            <CardActionArea >
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', minHeight: '15rem', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaPlus style={{ marginBottom: '0.7rem' }} />
                                    <Typography>Add new address</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </SectionWrapper>
            <Footer />
            <AddressDialog isAdd={isAdd} addressDetails={addressDetails} />
            <DetailsSavedDialog />

        </div>
    )
}

export default SelectAddress
