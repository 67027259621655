import React, { useEffect, useState } from 'react';
import { CartItemCount, SectionWrapper, SizedBox } from '../../components';
import { LabTestPackages } from '../../classes/lab-test-package-class';
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mainThemeColor } from '../../shared/constants';
import LabTestPackageDetailsDialog from './LabTestPackageDetailsDialog';
import { useSelector } from 'react-redux';

const SelectLabTestPackage = () => {

  const [packages, setPackages] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [packageName, setPackageName] = useState('');
  const [packageDetails, setPackageDetails] = useState({});
  const totalAmount = useSelector((states) => states.cart.totalAmount);

  const handleDetailsDialogOpen = (packageName, packageDetails) => {
    setPackageName(packageName);
    setPackageDetails(packageDetails);
    setDetailsDialogOpen(true);
    console.log("Package Details : " + packageDetails);
  };
  const handleDetailsDialogClose = () => {
    setDetailsDialogOpen(false);
  };

  useEffect(() => {
    const getPackages = async () => {
      var p = await LabTestPackages.getLabTestPackages();
      const ordered = Object.keys(p).sort().reduce(
        (obj, key) => {
          obj[key] = p[key];
          return obj;
        },
        {}
      );
      setPackages(ordered);
    };
    getPackages();
  }, []);

  return (
    <SectionWrapper increasePaddingTop={true} backgroundColor={'#FAF3FF'}>
      <Container maxWidth={'lg'}>
        <Typography variant='h4' fontWeight={'bold'} gutterBottom sx={{ color: mainThemeColor }}>Select Package</Typography>
        <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '15px', overflow: 'hidden' }}>
          {packages !== undefined && Object.keys(packages).length > 0 && Object.keys(packages).map((packageKey) => (
            <ListItem key={packageKey} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', backgroundColor: '#fff' }}>
              <ListItemButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', width: '100%' }}
                onClick={() => {
                  handleDetailsDialogOpen(
                    packageKey,
                    packages[packageKey],
                  )
                }}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <ListItemText
                      primary={packageKey}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                    <Typography variant='body2' color={'#666'} textAlign={'end'} gutterBottom>{`${packages[packageKey]['tests'].length} Tests`}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMediumScreen ? 'start' : 'end' }}>
                    <Typography variant='body2' color={'#666'} textAlign={'end'}>{`₹ ${packages[packageKey]['price']}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <CartItemCount packageName={packageKey} packageDetails={packages[packageKey]} size={'small'} />
                  </Grid>
                </Grid>
              </ListItemButton>
              <Divider style={{ width: '100%' }} />
            </ListItem>
          ))}
        </List>

        <SizedBox height={'2rem'} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button variant='contained'
            sx={{ backgroundColor: '#fff', color: mainThemeColor, textTransform: 'none', fontWeight: 'bold', ':hover': { backgroundColor: '#eee' } }}
            onClick={() => { navigate('/book-lab-test/select-address');  }}
          >
            Next
          </Button>
          <Typography fontWeight={'bold'}>Total Amount : ₹ {totalAmount}</Typography>
        </div>
      </Container>



      {/* <Grid container spacing={'1rem'}>
        {packages !== undefined && Object.keys(packages).length > 0 && Object.keys(packages).map((packageKey) => (
          <Grid item key={packageKey} xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ maxWidth: 500, borderRadius: 3, margin: 'auto', mb: isMediumScreen ? 2 : 0, boxShadow: 'none', border: '1px solid #ddd' }} onClick={() => { }}>
              <CardActionArea onClick={() => { }}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {packageKey}
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {`${packages[packageKey]['tests'].length} Tests`}
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {`₹ ${packages[packageKey]['price']}`}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <LabTestPackageDetailsDialog
        onClose={handleDetailsDialogClose}
        open={detailsDialogOpen}
        packageName={packageName}
        packageDetails={packageDetails}
      />
    </SectionWrapper>
  )
}

export default SelectLabTestPackage
