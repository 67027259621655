import React from 'react'
import { Blogs, Footer, Navbar } from '../../sections'

const AllBlogs = () => {
  return (
    <div>
      <Navbar />
      <Blogs isHomePage={false}/>
      <Footer />
    </div>
  )
}

export default AllBlogs
