import React from 'react';
import { Blogs, ContactUs, Description, Description2, Description3, Features, Footer, FrequentlyAskedQuestions, Header, Navbar } from '../../sections';
import image1 from '../../assets/Component_4.png';
import image2 from '../../assets/Frame-2.png';
import diagnosticsImage from '../../assets/cutting-edge-diagnostics.avif';
import dynamicReport from '../../assets/dynamic-report.avif';
import cloudServices from '../../assets/cloud-services.avif';
import turnaroundTime from '../../assets/fast-turnaround-time.avif';
import highPrecisionEquipment from '../../assets/high-precision-equipment.jpeg';
import digitisation from '../../assets/seamless-digitisation.avif';
import temperatureVigilance from '../../assets/temperature-vigilance.avif';
import cobasImage from '../../assets/cobas.png';
import fastTurnaroundTimeImage from '../../assets/fast_turnaround_times.png';
import increasedProductivityImage from '../../assets/increased_productivity.png';


const home = () => {

  const faq = [
    {
      "question": "Question 1",
      "answer": "Answer 1"
    },
    {
      "question": "Question 2",
      "answer": "Answer 2"
    },
  ];

  return (
    <div>
      <Navbar />
      <Header />
      <Description2 heading={'Why Choose Us for Your Lab Services?'} imageFirst={true} data={[
        ['Cutting-edge Diagnostics', 'We seamlessly blend the pinnacle of pathology expertise with state-of-the-art technology, unwavering empathy, uncompromising quality, and unwavering consistency. Our mission is to empower you to take charge of your health proactively and gain profound insights into your body\'s intricate workings.', diagnosticsImage],
        ['Dynamic Reporting', 'We revolutionize the way you track your past and current test results, presenting them with vivid, color-coded graphical representations that leap off the page. With effortless access to regular testing, you\'ll constantly wield pertinent information to master your body\'s performance.', dynamicReport],
        ['Technological Advancements', 'We harness the power of cutting-edge cloud services, ensuring your digital reports are at your fingertips for a lifetime, erasing the frustration of misplaced printed documents.', cloudServices],
        ['Lightning-Fast Turnaround Time (TAT)', 'The majority of our reports are in your hands within an astounding 8 hours. We comprehend the urgency of swift health report delivery, and we deliver on that promise with unmatched speed.', turnaroundTime],
        ['High-Precision Equipment', 'Precision is our creed, which is why we exclusively employ FDA-approved, top-tier machinery in our laboratory to guarantee spot-on reporting.', highPrecisionEquipment],
        ['Seamless Digitization', 'Our report generation process is fully automated, eliminating human intervention entirely. Reports are swiftly dispatched directly to you from the testing apparatus.', digitisation],
        ['Temperature Vigilance', 'Ensuring spot-on results demands vigilance, and we leverage cutting-edge technology to meticulously monitor and sustain the ideal temperature of blood samples from the moment they are collected until they undergo processing in our labs, maintaining the critical range of 2-8°C without fail.', temperatureVigilance],
      ]} />
      <Description increasePaddingTop={true} backgroundColor={'#FAF3FF'} heading={'Sample Collection'} data={[[
        'Enjoy the convenience of at-home sample collection',
        'Get accurate and reliable results from our state-of-the-art labs',
        'Experience the comfort of knowing your health is in good hands',
        'Take the first step towards a healthier you today',
      ]]} imageFirst={true} imageSrc={image1} />
      <Description increasePaddingBottom={true} backgroundColor={'#FAF3FF'} heading={'Advanced Technology'} data={[
        'At Cartula Diagnostics, we are powered by cutting-edge technology. Our state-of-the-art equipment ensures precision and reliability in your health assessments. From the latest diagnostic machinery to seamless telemedicine integration, we use technology to make your health journey simpler and more effective. Your health, our technology— partner with us for a healthier tomorrow.'
      ]} imageFirst={false} imageSrc={image2} />

      <Description3 heading={'Equipment we use'} imageFirst={false} data={[
        [
          'cobas® pure integrated solutions by Roche Diagnostics',
          [
            ['Designed to deliver excellence, while at the same time simplifying your daily work'],
            ['Combines clinical chemistry, immunochemistry and ISE testing, giving access to our'],
            ['Broad menu of more than 230 parameters – including many high medical value assays'],
            ['Simplifies daily operation by minimizing the hands on work for the operators, thus saving precious time'],
          ],
          cobasImage
        ],
        [
          'Get answers fast with short and predictable turnaround times.',
          [],
          increasedProductivityImage
        ],
        [
          'Increased Safety',
          [
            ['Utilizes single-use disposable AssayTips and AssayCups to completely eliminate the risk of sample carry over'],
            ['Sample probes are rinsed inside and outside with deionized water each time after dispensing a sample with option for special wash with basic and acidic solutions'],
            ['Ultrasonic mixing for non-contact mixing of sample and reagent to eliminate the risk of carryover during this event'],
          ],
          undefined
        ],
        [
          'Increased Productivity',
          [],
          fastTurnaroundTimeImage
        ],
      ]} />
      <Features />
      {/* <Blogs isHomePage={true} /> */}
      {/* <FrequentlyAskedQuestions questions={faq} /> */}
      <ContactUs />
      <Footer />
    </div>
  )
}

export default home
