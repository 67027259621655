import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase-setup/firebase';

class BlogsClass {
    constructor(allBlogs, homePageBlogs) {
        this.allBlogs = allBlogs;
        this.homePageBlogs = homePageBlogs;
    }

    setBlogs(blogs) {
        this.blogs = blogs;
    }

    async getHomePageBlogs() {
        if(this.homePageBlogs.length > 0) {
            return this.homePageBlogs;
        } else {
            console.log("Fetching home screen blogs from firestore");
            var blogs = [];
            const getBlogs = async () => {
                const q = query(collection(db, "diagnostics_blogs"), where("showOnHomeScreen", "==", true));
                const querySnapshot = await getDocs(q);
                for(let i = 0; i < querySnapshot.size; i++) {
                    let doc = querySnapshot.docs[i];
                    blogs[i] = {title: doc.get("title"), content: doc.get("content"), imageUrl: doc.get("imageUrl")}
                }
            };
            await getBlogs();
            this.homePageBlogs = blogs;
            return blogs;
        }
    }

    async getAllBlogs() {
        if(this.allBlogs.length > 0) {
            return this.allBlogs;
        } else {
            console.log("Fetching all blogs from firestore");
            var blogs = [];
            const getBlogs = async () => {
                const snapshot = await getDocs(collection(db, "diagnostics_blogs"));
                for(let i = 0; i < snapshot.size; i++) {
                    let doc = snapshot.docs[i];
                    blogs[i] = {title: doc.get("title"), content: doc.get("content"), imageUrl: doc.get("imageUrl")}
                }
            };
            await getBlogs();
            this.allBlogs = blogs;
            return blogs;
        }
    }
}


export var BLOGS = new BlogsClass([], []);