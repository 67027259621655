import React from 'react';
import { OrderHistory, ScheduledConsultations, Navbar, Orders, Consultations, AdviceOptions, BestSellers, Footer } from '../../sections';
import './userDashboard.css';
import { Button, Card, CardActionArea, CardActions, CardContent, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../features/user';
import { useDispatch } from 'react-redux';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { SectionWrapper } from '../../components';


const UserDashboard = () => {

  const navigate = useNavigate();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const data = [
    {
      title: 'Appointments',
      subTitle: 'View lab test bookings',
      address: '/user-dashboard/appointments',
    },
    {
      title: 'Account Details',
      subTitle: 'View and edit account details',
      address: '/account-details',
    },
  ];

  return (
    <div>
      <Navbar />
      <SectionWrapper backgroundColor={'#FAF3FF'} increasePaddingTop={isScreenSmall ? false : true}>
        <Grid container mt={6} mb={isScreenSmall ? 2 : 8} spacing={2} maxWidth={'lg'} width={'100%'} sx={{ display: 'flex', }} >
          {data.map((item) => <Grid item key={item.title} xs={12} sm={6} md={6} sx={{ display: 'flex', width: '100%', }}>
            <Card variant='outlined' onClick={() => { navigate(item.address) }} sx={{
              borderRadius: 4,
              width: '100%',
              backgroundColor: '#fff'
            }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {item.title}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} variant='body2' color="text.secondary">
                    {item.subTitle}
                  </Typography>
                  <FaArrowAltCircleRight />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>)}
        </Grid>
      </SectionWrapper>
      <Footer />
    </div>
  );
};

export default UserDashboard;