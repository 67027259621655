import React from 'react';
import './blogs.css';
import { Blog, Heading, SectionWrapper } from '../../components';
// import freeShippingImage from '../../assets/delivery-truck.png';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BLOGS } from '../../classes/blogs-class';

const Blogs = ({ isHomePage }) => {

  const [loadingBlogs, setLoadingBlogs] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [fetchedBlogs, setFetchedBlogs] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    setLoadingBlogs(true);
    const getBlogs = async () => {
      if (isHomePage) {
        const b = await BLOGS.getHomePageBlogs();
        setBlogs(b);
      } else {
        const b = await BLOGS.getAllBlogs();
        setBlogs(b);
      }

    };
    if (!fetchedBlogs) {
      getBlogs().then(() => {
        setLoadingBlogs(false);
        setFetchedBlogs(true);
      });
    }
  }, []);

  return (
    <SectionWrapper backgroundColor={'white'} increasePaddingTop={true}>
      <Grid container>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Heading text={"Blogs"} />
        </Grid>
        {isHomePage && <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}>
          <Link onClick={() => { navigate('/all-blogs'); }} underline='always' mt={1} color={'#888'} style={{cursor: 'pointer'}}>View All</Link>
        </Grid>}
      </Grid>

      <Grid container direction={'row'} maxWidth='xl' spacing={isSmallScreen ? 0 : 4}>
        {blogs.map((blog) =>
          <Blog
            key={blog.title}
            title={blog['title']}
            content={blog['content']}
            imageUrl={blog['imageUrl']}
          />
        )}
      </Grid>
    </SectionWrapper>
    // <div className='home__blogs'>

    // </div>
  )
}

export default Blogs
