import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { DialogContent, DialogContentText } from '@mui/material';
import { CartItemCount, SizedBox } from '../../components';

export default function LabTestPackageDetailsDialog(props) {
    const { onClose, open, packageName, packageDetails } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={'sm'} fullWidth={'sm'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <DialogTitle fontWeight={'bold'}>{packageName}</DialogTitle>
            <DialogContent>
                {packageDetails['tests'] !== undefined && <DialogContentText gutterBottom>{`Total tests: ${packageDetails['tests'].length}`}</DialogContentText>}
                <DialogContentText gutterBottom>{`Price: ₹ ${packageDetails['price']}`}</DialogContentText>
                <SizedBox height={'1rem'} />
                <Typography fontWeight={'bold'}>Tests included:</Typography>
                {packageDetails['tests'] !== undefined && packageDetails['tests'].map((test) => (
                    <Typography>{test}</Typography>
                ))}
                <SizedBox height={'2rem'} />
                <CartItemCount packageName={packageName} packageDetails={packageDetails} size={'small'} />
            </DialogContent>
        </Dialog>
    );
}

LabTestPackageDetailsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    packageName: PropTypes.string.isRequired,
    packageDetails: PropTypes.object.isRequired,
};