import React from 'react'
import { SectionWrapper } from '../../components'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { mainThemeColor } from '../../shared/constants'

// data = [[heading, text, imageUrl]]

const Description2 = ({ heading, data, imageFirst, backgroundColor }) => {

    const theme = useTheme();
    const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <SectionWrapper backgroundColor={backgroundColor === undefined ? '#fff' : backgroundColor}>
            <Typography variant='h4' fontWeight={'bold'} gutterBottom color={mainThemeColor}>{heading}</Typography>
            {data !== undefined &&
                data.map((value) => (
                    <Grid container direction={imageFirst ? 'row' : 'row-reverse'} pb={'2rem'}>
                        <Grid item xs={12} sm={12} lg={2}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', maxHeight: '150px' }}>
                                <img src={value[2]} width={'100%'} height={'100%'} style={{ objectFit: 'contain' }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={10} sx={{ display: 'flex', alignItems: 'center', mt: isScreenMedium ? '1rem' : '0' }}>
                            <div>
                                <Typography variant='h6' fontWeight={'bold'} gutterBottom>{value[0]}</Typography>
                                <Typography>{value[1]}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                ))
            }

        </SectionWrapper>
    )
}

export default Description2
