import React from 'react';
import { SectionWrapper } from '../../components';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Point from '@mui/icons-material/Circle';
import { mainThemeColor } from '../../shared/constants';

const Description = ({ heading, data, imageFirst, imageSrc, backgroundColor, increasePaddingTop, increasePaddingBottom }) => {

    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <SectionWrapper increasePaddingBottom={increasePaddingBottom} increasePaddingTop={increasePaddingTop} backgroundColor={backgroundColor === undefined ? '#fff' : backgroundColor}>
            <Grid container direction={imageFirst ? 'row' : 'row-reverse'}>
                <Grid item xs={12} sm={12} lg={5}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', maxHeight: '500px', marginBottom: isScreenSmall && '2rem' }}>
                        {imageSrc !== undefined && <img src={imageSrc} width={'100%'} height={'100%'} style={{ objectFit: 'contain' }} />}
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={7}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', paddingLeft: (imageFirst && !isScreenSmall) && '4rem', paddingRight: (!imageFirst  && !isScreenSmall) && '4rem', justifyContent: 'center', height: '100%' }}>
                        <Typography variant='h4' fontWeight={'bold'} gutterBottom color={mainThemeColor}>{heading}</Typography>
                        {data !== undefined && data.map((item, _) =>
                            Array.isArray(item) ? item.map((item2, _) =>
                            (
                                <div style={{ display: 'flex' }}>
                                    <Point sx={{ scale: '0.5', color: mainThemeColor }} />
                                    <Typography variant='body1' fontSize={isScreenSmall ? 16 : 18} gutterBottom pl={'0.5rem'}>{`${item2}`}</Typography>
                                </div>
                            )) :
                                (
                                    <Typography variant='body1' fontSize={isScreenSmall ? 16 : 18} gutterBottom>{item}</Typography>
                                ))}
                    </div>
                </Grid>
            </Grid>
        </SectionWrapper>
    )
}

export default Description
