import React, { useState } from 'react'
import { Heading, SectionWrapper, SizedBox } from '../../components'
import { Button, Container, Grid, IconButton, Snackbar, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { mainThemeColor } from '../../shared/constants';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationIcon from '@mui/icons-material/LocationOn';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { useMap } from 'react-leaflet/hooks'
import { Marker } from 'react-leaflet'
import { Icon, Popup } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import './contactUs.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';



const ContactUs = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [message, setMessage] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const theme = useTheme();
    const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
    const position = [13.032864334756619, 77.62988904389717];
    const [open, setOpen] = React.useState(false);

    const customIcon = new Icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/128/3177/3177361.png?ga=GA1.1.323229870.1683180648&semt=ais',
        iconSize: [38, 38]
    });

    const handleClick = () => {
        if (firstName.length === 0) {
            setSnackbarMessage('Please enter your first name.');
            setOpen(true);
            return;
        }
        if (lastName.length === 0) {
            setSnackbarMessage('Please enter your last name.');
            setOpen(true);
            return;
        }
        if (emailId.length === 0) {
            setSnackbarMessage('Please enter your Email ID.');
            setOpen(true);
            return;
        }
        if (message.length === 0) {
            setSnackbarMessage('Please enter your message.');
            setOpen(true);
            return;
        }
        setSnackbarMessage('Thank you for contacting us. We will get back to you soon.')
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <SectionWrapper increasePaddingTop={isScreenMedium ? false : true}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Heading text={'Contact Us'} />
                    <SizedBox height={'2rem'} />
                    <div style={{ display: 'flex' }}>
                        <LocationIcon />
                        <SizedBox width={'1rem'} />
                        <Typography>Address : #500, 1st floor, 5th block, 4th stage, HBR Layout, Bengaluru - 560043</Typography>
                    </div>
                    <SizedBox height={'1rem'} />
                    <div style={{ display: 'flex' }}>
                        <PhoneIcon />
                        <SizedBox width={'1rem'} />
                        <Typography>Phone Number : +91 897 111 9961 / +91 897 111 9921</Typography>
                    </div>
                    <SizedBox height={'1rem'} />
                    {/* <div style={{ display: 'flex' }}>
                        <EmailIcon />
                        <SizedBox width={'1rem'} />
                        <Typography>Email ID : contact@cartuladiagnostics.com</Typography>
                    </div> */}
                    <SizedBox height={'1rem'} />
                    <MapContainer center={position} zoom={15} scrollWheelZoom={false}>
                        <TileLayer
                            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker icon={customIcon} position={position}>
                            {/* <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup> */}
                        </Marker>
                    </MapContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={6} mt={isScreenMedium ? '2rem' : '0'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Container maxWidth={'sm'} sx={{ boxShadow: '0px 4px 20px 0px rgba(102, 44, 143, 0.25)', borderRadius: '15px' }}>
                        <Grid container spacing={'1rem'} mb={'0.5rem'} mt={'0.5rem'}>
                            <Grid item xs={12} sm={12} md={6} mt={'0.5rem'}>
                                <TextField id="standard-basic" label="First Name" variant="outlined" fullWidth onChange={(val) => { setFirstName(val.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} mt={'0.5rem'}>
                                <TextField id="standard-basic" label="Last Name" variant="outlined" fullWidth onChange={(val) => { setLastName(val.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField id="standard-basic" label="Email" variant="outlined" fullWidth onChange={(val) => { setEmailId(val.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Message"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={(val) => { setMessage(val.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    sx={{ backgroundColor: mainThemeColor, color: '#fff', textTransform: 'none', fontWeight: 'bold', ':hover': { backgroundColor: '#111' }, width: 'fit-content', mb: '1.5rem' }}
                                    onClick={handleClick}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
            />
        </SectionWrapper>
    )
}

export default ContactUs
