import React, { useEffect, useState } from 'react';
import './cartItemCount.css';
import { Container, Fab, Grid, Typography } from '@mui/material';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart, subtractItemFromCart } from '../../features/cart';



const CartItemCount = ({ packageName, packageDetails, buttonBackgroundColor, size }) => {

  const dispatch = useDispatch();
  const cart = useSelector((states) => states.cart.value);


  useEffect(() => {

  });

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2} direction={'row'} sx={{ mb: 0 }} alignItems={'center'}>
        <Grid item ml={'auto'}>
          <Fab size={size !== undefined ? size : "medium"} color="gray" aria-label="remove" style={{ boxShadow: "none", backgroundColor: { buttonBackgroundColor }, margin: "0" }} onClick={(e) => {
            e.stopPropagation();
            dispatch(subtractItemFromCart({
              ...packageDetails,
              "packageName": packageName,
            }));
          }}>
            <FaMinus />
          </Fab>
        </Grid>
        <Grid item>
          {cart[packageName] !== undefined ? <Typography>{cart[packageName]["numberInCart"]}</Typography> : <Typography>0</Typography>}
        </Grid>
        <Grid item mr={'auto'}>
          <Fab size={size !== undefined ? size : "medium"} color="gray" aria-label="add" style={{ boxShadow: "none", backgroundColor: { buttonBackgroundColor }, margin: "0" }} onClick={(e) => {
            e.stopPropagation();
            dispatch(addItemToCart({
              ...packageDetails,
              "packageName": packageName,
            }));
          }}>
            <FaPlus />
          </Fab>
        </Grid>
      </Grid>
    </Container>



    // <div className='cart_item_count' style={{ height: height, maxWidth: '200px' }}>

    // </div>
  )
}

export default CartItemCount
