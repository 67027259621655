import React from 'react'
import { SectionWrapper } from '../../components'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { mainThemeColor } from '../../shared/constants';
import Point from '@mui/icons-material/Circle';

const Description3 = ({ heading, data, imageFirst, backgroundColor }) => {

    const theme = useTheme();
    const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <SectionWrapper backgroundColor={backgroundColor === undefined ? '#fff' : backgroundColor}>
            <Typography variant='h4' fontWeight={'bold'} gutterBottom color={mainThemeColor}>{heading}</Typography>
            {data !== undefined &&
                data.map((value) => (
                    <Grid container direction={imageFirst ? 'row-reverse' : 'row'} pb={'2rem'}>
                        <Grid item xs={12} sm={12} lg={6} sx={{ display: 'flex', alignItems: 'center', mt: isScreenMedium ? '1rem' : '0' }}>
                            <div>
                                <Typography variant='h6' fontWeight={'bold'} gutterBottom>{value[0]}</Typography>
                                {value[1] !== undefined && value[1].map((item, _) =>
                                    Array.isArray(item) ? item.map((item2, _) =>
                                    (
                                        <div style={{ display: 'flex' }}>
                                            <Point sx={{ scale: '0.3', color: mainThemeColor }} />
                                            <Typography variant='body1' fontSize={isScreenSmall ? 16 : 18} gutterBottom pl={'0.5rem'}>{`${item2}`}</Typography>
                                        </div>
                                    )) :
                                        (
                                            <Typography variant='body1' fontSize={isScreenSmall ? 16 : 18} gutterBottom>{item}</Typography>
                                        ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', maxHeight: '300px' }}>
                                {value[2] !== undefined && <img src={value[2]} width={'100%'} height={'100%'} style={{ objectFit: 'contain', maxWidth: '600px' }} />}
                            </div>
                        </Grid>
                    </Grid>
                ))
            }

        </SectionWrapper>
    )
}

export default Description3
