import { Typography } from '@mui/material'
import React from 'react'

const PageNotFound = () => {
  return (
    <div style={{display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <Typography variant='h4'>Page not found</Typography>
    </div>
  )
}

export default PageNotFound
