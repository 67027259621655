import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        isOpen : false,
        alertText : '',
        alertDuration: 0,
        alertSeverity: 'success',
    }
};

export const alertSlice = createSlice({
    name: "alert",
    initialState: initialState,
    reducers: {
        setAlert: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;