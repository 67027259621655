import React from 'react';
import { SectionWrapper } from '../../components/index.js';
import { Description, Footer, Navbar } from '../../sections/index.js';
import { Container, Typography } from '@mui/material';
import aboutUsImage from '../../assets/about_us.png';

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <SectionWrapper backgroundColor={'#FAF3FF'} increasePaddingTop={true} removePaddingBottom={true}>
        <Typography variant='h3' fontWeight={'bold'} gutterBottom color={'#662C8F'} textAlign={'center'}>About Us</Typography>
        <Container maxWidth={'lg'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='body1' fontSize={20} gutterBottom sx={{ color: '#111' }}>
            Cartula Diagnostics, an initiative from Cartula Health India, is a state-of-the-art diagnostic center at namma Bengaluru. Cartula Health India was established in 2019, with the goal of expanding access to quality health care across rural India through telemedicine. Using the Cartula Health App, patients across India can quickly connect with leading board-certified physicians, psychiatrists, therapists and a range of other health care specialists. It revolutionizes the Indian health care system by allowing rural India to access the best healthcare. The launch of the diagnostics chain is a significant stride towards accessible and inclusive healthcare for all.
          </Typography>
          <div style={{ display: 'flex', width: '70vw', maxWidth: '600px', justifyContent: 'center' }}>
            <img src={aboutUsImage} style={{ width: '100%' }} />
          </div>
        </Container>
      </SectionWrapper>
      <Description
        heading={'Mission'}
        data={['Cartula Diagnostics believes that preventive care and the knowledge that it holds in healthcare will change the view of how it is provided in a community. We strive to achieve accessible, affordable, and high-quality healthcare for all.']}
        imageFirst={false}
        imageSrc={'https://img.freepik.com/free-vector/positive-tiny-people-sitting-walking-near-huge-target-with-arrow-isolated-flat-vector-illustration-cartoon-business-team-achieving-goal-aim-marketing-strategy-achievement-concept_74855-10139.jpg?w=1800&t=st=1695997059~exp=1695997659~hmac=2b96d694b1c95f193af4b81309c8e0cdf6b215b41cae1dda56d31879ec5aaaff'}
      />
      <Description
        heading={'Vision'}
        data={['Build Swasth India - empower consumers across every corner of the country to simplify their healthcare needs.']}
        imageFirst={true}
        imageSrc={'https://img.freepik.com/free-vector/business-concept-vector-illustration-businessman-who-running-with-increase-graphic-chart-see-imagination-future_1150-39743.jpg?w=2000&t=st=1695997313~exp=1695997913~hmac=1f69ddf46f05522f1296cf9787593cdab233593d2603439b6ec246d7a7dd64fa'}
      />
      <Description
        heading={'Values'}
        data={[
          'Accessibility: We build the network to provide same-day access to high-quality healthcare to rural and urban communities alike.',
          'Ease of use: We eliminate friction before, during, and after the visit. Delighting customers by simplifying processes is what we strive for.',
          'Privacy-First: We believe in empowering our customers to control and choose how their data is used through the principles of transparency, choice, and control.',
          'Transparency: We aim to give our consumers clarity on the services offered and their price. And, we do it by using the words that everyone can understand.',
          'Quality: We will never compromise the quality of services offered to our consumers. And, we do so by using the latest-and-greatest technology.',
        ]}
        imageFirst={false}
        imageSrc={'https://img.freepik.com/free-vector/partners-holding-big-jigsaw-puzzle-pieces_74855-5278.jpg?w=2000&t=st=1695997510~exp=1695998110~hmac=9c1e16e15b2164e769f3cdb002178698eb0a63dd5e997baa7b3f0c1d6eaddffc'}
      />
      <Footer />
    </div>
  )
}
// 
export default AboutUs
