import React, { useEffect, useState } from 'react';
import './userLogin.css';
import { auth, provider, db } from '../../firebase-setup/firebase';
import { collection, query, where, getDocs, addDoc, limit, doc, setDoc } from 'firebase/firestore';
import { signInWithPopup, getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../features/user';
import { Footer } from '../../sections';
import { Backdrop, Button, CircularProgress, Divider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Navbar from '../../sections/navbar/Navbar';
import { setAlert } from '../../features/alert';
import { SectionWrapper } from '../../components';

const UserLogin = () => {

  const location = useLocation();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const textFieldStyle = { marginTop: "2vw" };
  const dividerStyle = { margin: "2vw 0 0 0", };
  const divStyle = { height: isScreenSmall ? '1rem' : 0 };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogIn, setIsLogIn] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [useLocationUsed, setUseLocationUsed] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const collectionRef = collection(db, 'diagnostics_users');

  const signInWithGoogle = async () => {
    setBackdropOpen(true);
    signInWithPopup(auth, provider).then(async (result) => {
      await handleUserLoginWithGoogle(auth.currentUser.uid, auth.currentUser.email, auth.currentUser.displayName);
      navigate('/');
      setBackdropOpen(false);
      dispatch(setAlert({
        isOpen: true,
        alertText: 'Logged in successfully',
        alertDuration: 2000,
        alertSeverity: 'success',
      }));
    }).catch((error) => {
      setBackdropOpen(false);
      dispatch(setAlert({
        isOpen: true,
        alertText: 'Some error occurred while logging in',
        alertDuration: 2000,
        alertSeverity: 'error',
      }));
    });
  };

  const signInWithEmail = async () => {
    var hasError = false;
    setBackdropOpen(true);
    const auth = getAuth();
    const response = await signInWithEmailAndPassword(auth, email, password).catch((err) => {
      console.log(err);
      hasError = true;
    });
    console.log("Sign in response: " + response);
    if (!hasError) {
      await handleUserLogin(auth.currentUser.uid);
      const navpath = location.state !== undefined && location.state !== null ? (location.state.navpath !== undefined ? location.state.navpath : '/') : '/';
      dispatch(setAlert({
        isOpen: true,
        alertText: 'Logged in successfully',
        alertDuration: 2000,
        alertSeverity: 'success',
      }));
      setBackdropOpen(false);
      navigate(navpath);
    } else {
      setBackdropOpen(false);
      dispatch(setAlert({
        isOpen: true,
        alertText: 'Invalid username or password',
        alertDuration: 2000,
        alertSeverity: 'error',
      }));
    }
  }

  const registerWithEmail = async () => {
    var hasError = false;
    setBackdropOpen(true);
    console.log("email" + email);
    console.log("password" + password);
    const auth = getAuth();
    const response = await createUserWithEmailAndPassword(auth, email, password).catch((err) => {
      hasError = true;
    });
    if (!hasError) {
      console.log("User created! : " + response);
      await handleUserCreated(response.user.uid, response.user.email, firstName, lastName);
      setBackdropOpen(false);
    } else {
      setBackdropOpen(false);
      dispatch(setAlert({
        isOpen: true,
        alertText: 'An error occurred',
        alertDuration: 2000,
        alertSeverity: 'error',
      }));
    }
  }


  const handleUserCreated = async (uid, emailId, fName, lName) => {
    const docRef = doc(db, 'diagnostics_users', uid);
    const response = await setDoc(docRef, {
      uid: auth.currentUser.uid,
      firstName: fName,
      lastName: lName,
      emailId: emailId,
      phoneNumber: '',
      cart: [],
      scheduledAppointments: [],
      appointmentHistory: [],
      addresses: [],
      totalAddresses: 0,
    }).then((response) => {
      dispatch(login({
        isLoggedIn: true,
        uid: uid,
        firstName: fName,
        lastName: lName,
        emailId: emailId,
        phoneNumber: '',
        scheduledAppointments: [],
        appointmentHistory: [],
        addresses: [],
        totalAddresses: 0,
      }));
    }).catch((error) => {

    });
    console.log("Document Added : " + response);
    // dispatch(login({ userClass: new UserClass() }));
    dispatch(setAlert({
      isOpen: true,
      alertText: 'Registered successfully!',
      alertDuration: 2000,
      alertSeverity: 'success',
    }));
    navigate('/');
  };

  const handleUserLogin = async (uid) => {
    const q = query(collection(db, 'diagnostics_users'), where('uid', '==', uid), limit(1));
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        dispatch(login({
          isLoggedIn: true,
          uid: uid,
          firstName: doc.data()['firstName'],
          lastName: doc.data()['lastName'],
          emailId: doc.data()['emailId'],
          phoneNumber: doc.data()['phoneNumber'],
          scheduledAppointments: doc.data()['scheduledAppointments'],
          appointmentHistory: doc.data()['appointmentHistory'],
          addresses: doc.data()['addresses'],
          totalAddresses: 0,
        }));
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleUserLoginWithGoogle = async (uid, emailId, name) => {
    const q = query(collection(db, 'diagnostics_users'), where('uid', '==', uid), limit(1));
    await getDocs(q).then(async (querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach(async (doc) => {
          console.log(doc.data());
          dispatch(login({
            isLoggedIn: true,
            uid: uid,
            firstName: doc.data()['firstName'],
            lastName: doc.data()['lastName'],
            emailId: doc.data()['emailId'],
            phoneNumber: doc.data()['phoneNumber'],
            scheduledAppointments: doc.data()['scheduledAppointments'],
            appointmentHistory: doc.data()['appointmentHistory'],
            addresses: doc.data()['addresses'],
            totalAddresses: 0,
          }));
        });
      } else {
        await handleUserCreated(uid, emailId, name, '');
      }

    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    if (useLocationUsed !== true && location.state !== null && location.state.openLogin !== undefined) {
      setIsLogIn(location.state.openLogin ?? true);
      setUseLocationUsed(true);
      console.log('isLogIn : ' + location.state.openLogin ?? true);
    } else {
      setUseLocationUsed(true);
    }
  }, [isLogIn]);

  return (
    <SectionWrapper>
      <Navbar />
      <div className='user_login__container'>
        <div className='user_login__container__content'>
          <h1>{isLogIn ? 'Welcome back' : 'Welcome!'}</h1>
          <Typography sx={{ mt: '0.5rem' }}>Please enter your details</Typography>
          <div style={divStyle} />
          {/* <Button onClick={signInWithGoogle} variant="contained" style={{ marginTop: "2vw", color: "#444", backgroundColor: "white", border: "1px solid #ccc", borderRadius: "5px", boxShadow: "none", height: "3rem", fontWeight: "bold", textTransform: 'none' }}>
            <img style={{ width: '20px', marginRight: '1rem' }} src='https://cdn-icons-png.flaticon.com/512/281/281764.png?w=1800&t=st=1684668341~exp=1684668941~hmac=115d1fcb5c28be6a38da50b2f50be385f88e624a3105b8329cd2542156bf1fec' />
            <Typography variant='body2'>Log in with Google</Typography>
          </Button>
          <div style={divStyle} />
          <Divider orientation="horizontal" textAlign='center' style={dividerStyle} flexItem>
            or
          </Divider>
          <div style={divStyle} /> */}
          <TextField id="standard-basic" label="Email" variant="outlined" style={textFieldStyle} onChange={(val) => { setEmail(val.target.value) }} />
          {!isLogIn && <div style={divStyle} />}
          {!isLogIn &&
            <TextField id="standard-basic" label="First Name" variant="outlined" style={textFieldStyle} onChange={(val) => { setFirstName(val.target.value) }} />
          }
          {!isLogIn && <div style={divStyle} />}
          {!isLogIn &&
            <TextField id="standard-basic" label="Last Name" variant="outlined" style={textFieldStyle} onChange={(val) => { setLastName(val.target.value) }} />
          }
          <div style={divStyle} />
          <TextField id="standard-basic" label="Password" variant="outlined" style={textFieldStyle} onChange={(val) => { setPassword(val.target.value) }} />
          <div style={divStyle} />
          {isLogIn && <Typography sx={{ mt: '1rem', textDecoration: 'underline', cursor: 'pointer' }}>Forgot Password</Typography>}
          <div style={divStyle} />
          <Button variant="contained" onClick={isLogIn ? signInWithEmail : registerWithEmail} style={{ marginTop: "2vw", color: "white", backgroundColor: "black", borderRadius: "5px", boxShadow: "none", height: "3rem", fontWeight: "bold" }}>
            {isLogIn ? 'Log in' : 'Register'}
          </Button>
          <div style={divStyle} />
          <div className='user_login__container__content__sign_up'>
            <Typography sx={{ mt: '1rem' }}>{isLogIn ? "Don't have an account?" : 'Already have an account?'}</Typography>
            <Typography sx={{ mt: '1rem', ml: '1rem', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setIsLogIn(!isLogIn) }}>{isLogIn ? 'Sign up for free' : 'Log in'}</Typography>
          </div>
        </div>
        <div className='user_login__container__image'>
          <img src='https://img.freepik.com/free-vector/my-password-concept-illustration_114360-4294.jpg?w=1480&t=st=1698067208~exp=1698067808~hmac=4a8903fa218f6475f5da527e5cb3e1fc6b724cc63af50ff885d7ce9a4cb55476' />
        </div>
      </div>
      <Footer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SectionWrapper>
  )
}

export default UserLogin
