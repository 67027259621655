import React from 'react';
import './blog.css';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { Grid, Rating } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const Blog = ({ key, title, content, imageUrl }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const retrievedContent = content.replace(/%n%/g, '\n');

  return (
    // <div className='blog' onClick={() => {navigate('blog', {state: {title: title, content: content, imageUrl, imageUrl}})}}>
    //     <div className='blog_image'>
    //         <img src={imageUrl} alt="blogImage" />
    //     </div>
    //     <div className='blog_content'>
    //         <h3>{title}</h3>
    //         <p>{content}</p>
    //     </div>
    // </div>

    <Grid key={key} item xs={12} sm={12} md={6} lg={4}>
      <Card sx={{ maxWidth: 500, borderRadius: 5, margin: 'auto', mb: isMediumScreen ? 2 : 0 }} onClick={() => {navigate('/blog', {state: {title: title, content: retrievedContent, imageUrl, imageUrl}})}}>
        <CardActionArea onClick={() => { }}>
          <CardMedia
            component="img"
            height="250"
            image={imageUrl}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {title}
            </Typography>
            <Typography gutterBottom variant="body1" component="div" sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3, // Number of lines to display
              WebkitBoxOrient: 'vertical',
            }}>
              {retrievedContent}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default Blog
